import {useEffect, useState} from 'react'
import {filled, usePrevious} from '../../Helpers'
import {has, isArray, isEmpty, isString} from 'lodash'

export default function LaravelErrorOutput({name = null, submitErrors = null, nameOnly = true, className = ''}) {

    const [extraErrors, setExtraErrors] = useState([])
    const previous = usePrevious({submitErrors})

    useEffect(() => {

        if (isEmpty(name) || isEmpty(submitErrors)) {
            setExtraErrors([])
            return
        }

        if (isString(submitErrors) && !nameOnly) {
            setExtraErrors([submitErrors])
            return
        }

        if (isArray(submitErrors)) {
            setExtraErrors(submitErrors)
            return
        }

        let foundErrors = null

        if (has(submitErrors, name)) {

            if (filled(submitErrors[name])) {
                foundErrors = submitErrors[name]
                if (isString(foundErrors)) {
                    setExtraErrors([foundErrors])
                    return
                }

                if (isArray(foundErrors)) {
                    setExtraErrors(foundErrors)
                    return
                }

            }
            setExtraErrors([])
            return
        }

        if (isEmpty(submitErrors?.errors) || !has(submitErrors.errors, name) || isEmpty(submitErrors.errors[name])) {
            setExtraErrors([])
            return
        }

        foundErrors = submitErrors.errors[name]

        if (isString(foundErrors)) {
            setExtraErrors([foundErrors])
            return
        }

        if (isArray(foundErrors)) {
            setExtraErrors(foundErrors)
            return
        }

        setExtraErrors([])
    }, [submitErrors])

    const classString = 'mt-2 text-sm text-red-600'

    return (
        <>
            {
                filled(extraErrors)
                &&
                <div className="mt-2 text-sm text-red-600">
                    {extraErrors.count > 1 ? (
                        <p className={classString}>
                            {extraErrors[0]}
                        </p>
                    ) : (
                        <ul>
                            {extraErrors.map((err, i) => (
                                <li key={i}>
                                    <span className={classString}>{err}</span>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            }
        </>
    )
}
