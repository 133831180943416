import { classNames } from '../Helpers'

export default function Tooltip({ className, title, subtitle, position = 'right', padding = 'px-3 py-1' }) {

    var positionClasses = ''

    switch (position) {

        case 'right':
            positionClasses = 'ml-8'
            break;

        case 'bottom':
            positionClasses = 'w-48 -ml-40 mt-6'
            break;
    }

    return (
        <div
            className={classNames("tooltip rounded shadow-lg border border-gray-100 bg-white", padding, className, positionClasses)}>
            <div className="font-bold text-gray-700">{title}</div>
            {subtitle &&
                <div className="text-xs text-gray-500">{subtitle}</div>
            }
        </div>
    )
}
